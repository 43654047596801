import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/community.tsx";
export const pageTitle = "Design resources";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Warning = makeShortcode("Warning");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "design-resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#design-resources",
        "aria-label": "design resources permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Design resources`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#rules"
        }}>{`Rules`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#key-colors"
        }}>{`Key colors`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#logos"
        }}>{`Logos`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#history"
        }}>{`History`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#credits"
        }}>{`Credits`}</a></li>
    </ul>
    <h2 {...{
      "id": "rules",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#rules",
        "aria-label": "rules permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Rules`}</h2>
    <Warning mdxType="Warning">
      <p>{`LY Corporation owns all rights to the logos. The logos and PDF files in this page
are `}<em parentName="p">{`not`}</em>{` licensed under any open source license.
`}<a parentName="p" {...{
          "href": "https://linecorp.com/en/inquiry/corporate/form.html"
        }}>{`Contact LY Corporation`}</a>{`
about use in television, magazines or other forms of media, or to seek
clarification.`}</p>
    </Warning>
    <p>{`Follow the rules outlined in `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria/raw/main/site/src/design/armeria_guideline.pdf"
      }}>{`the guideline (PDF)`}</a>{`
when using the logo. In principle, all logos must be used as-is and without alteration or modification.`}</p>
    <h2 {...{
      "id": "key-colors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#key-colors",
        "aria-label": "key colors permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Key colors`}</h2>
    <div style={{
      "display": "flex",
      "flexWrap": "wrap",
      "justifyContent": "stretch",
      "alignItems": "stretch",
      "textAlign": "center",
      "fontSize": "85%"
    }}>
  <div style={{
        "background": "#ff0089",
        "color": "#ffffff",
        "width": "132px",
        "padding": "0.4rem 0 0.5rem 0",
        "margin": "8px",
        "border": "solid 1px #3a3a3a"
      }}>Magenta <code style={{
          "background": "none"
        }}>#ff0089</code></div>
  <div style={{
        "background": "#ff9dc3",
        "color": "#3a3a3a",
        "width": "132px",
        "padding": "0.4rem 0 0.5rem 0",
        "margin": "8px",
        "border": "solid 1px #3a3a3a"
      }}>Pink <code style={{
          "background": "none"
        }}>#ff9dc3</code></div>
  <div style={{
        "background": "#3a3a3a",
        "color": "#ffffff",
        "width": "132px",
        "padding": "0.4rem 0 0.5rem 0",
        "margin": "8px",
        "border": "solid 1px #3a3a3a"
      }}>Dark gray <code style={{
          "background": "none"
        }}>#3a3a3a</code></div>
  <div style={{
        "background": "#a6a6a6",
        "color": "#3a3a3a",
        "width": "132px",
        "padding": "0.4rem 0 0.5rem 0",
        "margin": "8px",
        "border": "solid 1px #3a3a3a"
      }}>Gray <code style={{
          "background": "none"
        }}>#a6a6a6</code></div>
  <div style={{
        "background": "#ffffff",
        "color": "#3a3a3a",
        "width": "132px",
        "padding": "0.4rem 0 0.5rem 0",
        "margin": "8px",
        "border": "solid 1px #3a3a3a"
      }}>White <code style={{
          "background": "none"
        }}>#ffffff</code></div>
    </div>
    <h2 {...{
      "id": "logos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#logos",
        "aria-label": "logos permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Logos`}</h2>
    <div {...{
      "style": {
        "display": "flex",
        "flexWrap": "wrap",
        "justifyContent": "stretch",
        "alignItems": "stretch",
        "textAlign": "center"
      }
    }}>{`
  `}<figure parentName="div" {...{
        "style": {
          "margin": "8px"
        }
      }}>{`
    `}<img parentName="figure" {...{
          "src": "https://github.com/line/armeria/raw/main/site/src/design/armeria_icon.svg",
          "width": 232,
          "height": 128,
          "style": {
            "border": "solid 1px #3a3a3a"
          }
        }}></img>{`
    `}<figcaption parentName="figure">{`Logo without text`}</figcaption>{`
  `}</figure>{`
  `}<figure parentName="div" {...{
        "style": {
          "margin": "8px"
        }
      }}>{`
    `}<img parentName="figure" {...{
          "src": "https://github.com/line/armeria/raw/main/site/src/design/armeria_icon_and_text_horiz.svg",
          "width": 232,
          "height": 128,
          "style": {
            "border": "solid 1px #3a3a3a"
          }
        }}></img>{`
    `}<figcaption parentName="figure">{`Logo with text`}</figcaption>{`
  `}</figure>{`
  `}<figure parentName="div" {...{
        "style": {
          "margin": "8px"
        }
      }}>{`
    `}<img parentName="figure" {...{
          "src": "https://github.com/line/armeria/raw/main/site/src/design/armeria_icon_and_text_horiz_grayscale.svg",
          "width": 232,
          "height": 128,
          "style": {
            "border": "solid 1px #3a3a3a"
          }
        }}></img>{`
    `}<figcaption parentName="figure">{`Grayscale logo with text`}</figcaption>{`
  `}</figure>{`
  `}<figure parentName="div" {...{
        "style": {
          "margin": "8px"
        }
      }}>{`
    `}<img parentName="figure" {...{
          "src": "https://github.com/line/armeria/raw/main/site/src/design/armeria_icon_and_text_horiz_inverted.svg",
          "width": 232,
          "height": 128,
          "style": {
            "border": "solid 1px #3a3a3a",
            "background": "#3a3a3a"
          }
        }}></img>{`
    `}<figcaption parentName="figure">{`Logo with text`}<br parentName="figcaption"></br>{`(Inverted)`}</figcaption>{`
  `}</figure>{`
  `}<figure parentName="div" {...{
        "style": {
          "margin": "8px"
        }
      }}>{`
    `}<img parentName="figure" {...{
          "src": "https://github.com/line/armeria/raw/main/site/src/design/armeria_icon_and_text_horiz_inverted_grayscale.svg",
          "width": 232,
          "height": 128,
          "style": {
            "border": "solid 1px #3a3a3a",
            "background": "#3a3a3a"
          }
        }}></img>{`
    `}<figcaption parentName="figure">{`Grayscale logo with text`}<br parentName="figcaption"></br>{`(Inverted)`}</figcaption>{`
  `}</figure>{`
  `}<figure parentName="div" {...{
        "style": {
          "margin": "8px"
        }
      }}>{`
    `}<img parentName="figure" {...{
          "src": "https://github.com/line/armeria/raw/main/site/src/design/armeria_icon_and_text_horiz_inverted_pink.svg",
          "width": 232,
          "height": 128,
          "style": {
            "border": "solid 1px #3a3a3a",
            "background": "#ff0089"
          }
        }}></img>{`
    `}<figcaption parentName="figure">{`Logo with text`}<br parentName="figcaption"></br>{`(Inverted pink)`}</figcaption>{`
  `}</figure>{`
  `}<figure parentName="div" {...{
        "style": {
          "margin": "8px"
        }
      }}>{`
    `}<img parentName="figure" {...{
          "src": "https://github.com/line/armeria/raw/main/site/src/design/armeria_icon_and_text_vert.svg",
          "width": 232,
          "height": 128,
          "style": {
            "border": "solid 1px #3a3a3a"
          }
        }}></img>{`
    `}<figcaption parentName="figure">{`Logo with text`}<br parentName="figcaption"></br>{`(horizontal layout)`}</figcaption>{`
  `}</figure>{`
  `}<figure parentName="div" {...{
        "style": {
          "margin": "8px"
        }
      }}>{`
    `}<img parentName="figure" {...{
          "src": "https://github.com/line/armeria/raw/main/site/src/design/armeria_text.svg",
          "width": 232,
          "height": 128,
          "style": {
            "border": "solid 1px #3a3a3a"
          }
        }}></img>{`
    `}<figcaption parentName="figure">{`Text only`}</figcaption>{`
  `}</figure>
    </div>
    <h2 {...{
      "id": "history",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#history",
        "aria-label": "history permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`History`}</h2>
    <p>{`If you are interested in how we ended up with the current logo, check these files:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/raw/main/site/src/design/armeria_draft.pdf"
        }}>{`The draft for the current logo`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/raw/main/site/src/design/armeria_icon_legacy.svg"
        }}>{`The old logo`}</a></li>
    </ul>
    <h2 {...{
      "id": "credits",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#credits",
        "aria-label": "credits permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Credits`}</h2>
    <ul>
      <li parentName="ul">{`Ka-young Ryu - the current logo`}</li>
      <li parentName="ul">{`Woo-jeong Choi - the old logo`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      